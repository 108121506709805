import React from "react";
import MainContent from "./pages/Homepage/HomePage.jsx";
import "./index.css";

const App = () => {
  return (
    <div>
      <div className="px-8 font-sans">
        <MainContent />
      </div>
    </div>
  );
};

export default App;
