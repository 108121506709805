import React from "react";
import {FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";

const Header = () => {
  return (
    <div className="flex justify-end items-center px-4 py-3 bg-white">
      <div className="flex space-x-4 cursor-pointer">
        <FaFacebook className="text-2xl hover:text-blue-600" />
        <FaTwitter className="text-2xl hover:text-blue-400" />
        <FaInstagram className="text-2xl hover:text-pink-600 " />
      </div>
    </div>
  );
};

export default Header;
