import { useState, useEffect } from "react";
import axios from "axios";
import shopping from "../../assets/shopping.svg";
import shoppingAssistant from "../../assets/shopping-assistant.svg";
import price from "../../assets/price.svg";
import discount from "../../assets/discount.svg";
import results from "../../assets/results.svg";
import send from "../../assets/submit-msg.svg";
import Header from "../../components/header/Header";
import Loader from "../../components/loader/Loader";

const MainContent = () => {
  const [query, setQuery] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [popupVisible, setPopupVisible] = useState(true);
  const [threadId, setThreadId] = useState(null);

  const handleCreateThread = async () => {
    try {
      const res = await axios.post("https://api.buai.co/create_thread/");
      if (res.data && res.data.response) {
        console.log("Thread created:", res.data.response);
        setThreadId(res.data.response);
      } else {
        console.error("Unexpected response structure:", res.data);
      }
    } catch (error) {
      console.error("Error creating thread:", error);
    }
  };

  const handleStartChat = async () => {
    try {
      await handleCreateThread();
      setPopupVisible(false);
    } catch (error) {
      console.error("Error starting chat:", error);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!query.trim() || !threadId) return;
    setChatHistory((prev) => [...prev, { type: "user", text: query }]);
    setQuery("");
    setLoading(true);

    try {
      const res = await axios.post(
        `https://api.buai.co/get_response/?thread_id=${threadId}`,
        { query }
      );

      console.log("API Response:", res.data);
      if (res.data && res.data.response) {
        setChatHistory((prev) => [
          ...prev,
          { type: "bot", text: res.data.response },
        ]);
      } else {
        console.error("Unexpected response structure:", res.data);
      }
    } catch (error) {
      console.error("Error fetching response:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setPopupVisible(true);
  }, []);

  return (
    <div className="flex flex-col xl:h-screen lg:h-screen md:h-screen h-[750px] overflow-hidden pb-2">
      <Header />
      {popupVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <h2 className="text-2xl font-bold mb-4">Start Chat</h2>
            <p className="text-sm text-gray-600 mb-6">
              Click "Start" to begin your chat with ebuAI.
            </p>
            <button
              onClick={handleStartChat}
              className="bg-[#0064D2] text-white px-8 py-2 rounded-lg"
            >
              Start
            </button>
          </div>
        </div>
      )}

      <div className="flex flex-col items-center justify-between flex-1 text-center overflow-y-auto bg-[#F9FAFC] rounded-lg shadow-[inset_0px_-2px_14px_0px_rgba(0,0,0,0.08)] gap-2 px-4 lg:px-0">
        {chatHistory.length === 0 && (
          <div className="p-2 bg-white rounded-lg shadow-md mt-10 sm:mt-6">
            <img src={shopping} alt="BuAI Logo" className="max-w-full h-auto" />
          </div>
        )}
        {chatHistory.length === 0 && (
          <div className="flex flex-col items-center justify-center flex-grow">
            <h1 className="text-3xl font-bold">Welcome to ebuAI</h1>
            <p className="mt-4 text-sm sm:text-base text-[#5E5E5E] leading-relaxed">
              Introducing ebuAI, your free AI shopping assistant! No more
              endless scrolling - <br />
              just compare prices from different sellers across eBay in seconds.
              <br /> Let ebuAI do the hard work and find the best deals for you.
            </p>
            <p className="text-sm sm:text-base text-[#5E5E5E]">
              Just say, "Go nuts!"
            </p>
            <div className="flex flex-col items-center justify-around w-full max-w-xs gap-4 mt-4">
              <div className="flex items-center gap-3 ms-6">
                <img src={shoppingAssistant} alt="AI Shopping Assistant" />
                <p className="text-sm">AI Shopping Assistant</p>
              </div>
              <div className="flex items-center gap-3">
                <img src={price} alt="Price Comparisons" />
                <p className="text-sm">Price Comparisons</p>
              </div>
              <div className="flex items-center gap-3 me-2">
                <img src={discount} alt="Discount Hunter" />
                <p className="text-sm">Discount Hunter</p>
              </div>
              <div className="flex items-center gap-3">
                <img src={results} alt="Real-Time Results" />
                <p className="text-sm">Real-Time Results</p>
              </div>
            </div>
          </div>
        )}
        <div className="w-full mt-6 p-4 flex-1 overflow-y-auto">
          {chatHistory.map((chat, index) => (
            <div
              key={index}
              className={`p-3 rounded mb-2 ${
                chat.type === "user"
                  ? "bg-gray-200 text-right"
                  : "bg-gray-100 text-left"
              }`}
              style={{ wordBreak: "break-word" }}
            >
              <strong>{chat.type === "user" ? "You:" : "Bot:"}</strong>
              <p>{chat.text}</p>
            </div>
          ))}
          {loading && (
            <div className="p-3 rounded">
              <strong>Bot:</strong>
              <div>
                <Loader />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="w-full pt-4 px-2">
        <form className="flex" onSubmit={handleSubmit}>
          <div className="flex-1 border border-[#B0B0B0] p-1 rounded-full md:p-2">
            <input
              type="text"
              placeholder="Tell me what do you want?"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              className="flex-1 p-1 w-full border-none outline-none text-[#5E5E5E] md:p-2"
              disabled={loading}
            />
          </div>
          <button
            type="submit"
            className="bg-[#0064D2] text-white xl:p-4 lg:p-4 md:p-2 md:px-4 px-3 p-2 ml-3 rounded-full"
            disabled={loading}
          >
            <img
              src={send}
              alt="Send"
              className="w-4 h-4 md:w-6 md:h-6 lg:w-auto lg:h-auto"
            />
          </button>
        </form>
      </div>
    </div>
  );
};

export default MainContent;
